import React , { useState }from 'react'
import { Modal ,Button} from 'react-bootstrap'

function Location() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    return (
        <>
            <p class="pt-2 text-gray-100 text-xs lg:text-sm flex items-center justify-center lg:justify-start">
                        <svg  onClick={handleShow}  class="h-4 fill-current text-purple-700 pr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm7.75-8a8.01 8.01 0 0 0 0-4h-3.82a28.81 28.81 0 0 1 0 4h3.82zm-.82 2h-3.22a14.44 14.44 0 0 1-.95 3.51A8.03 8.03 0 0 0 16.93 14zm-8.85-2h3.84a24.61 24.61 0 0 0 0-4H8.08a24.61 24.61 0 0 0 0 4zm.25 2c.41 2.4 1.13 4 1.67 4s1.26-1.6 1.67-4H8.33zm-6.08-2h3.82a28.81 28.81 0 0 1 0-4H2.25a8.01 8.01 0 0 0 0 4zm.82 2a8.03 8.03 0 0 0 4.17 3.51c-.42-.96-.74-2.16-.95-3.51H3.07zm13.86-8a8.03 8.03 0 0 0-4.17-3.51c.42.96.74 2.16.95 3.51h3.22zm-8.6 0h3.34c-.41-2.4-1.13-4-1.67-4S8.74 3.6 8.33 6zM3.07 6h3.22c.2-1.35.53-2.55.95-3.51A8.03 8.03 0 0 0 3.07 6z"/>
                        </svg> Bharatpur-10, Chitwan , Nepal
                </p> 

                <Modal show={show} onHide={handleClose} >
                                <Modal.Header className="bg-gray-700" closeButton>
                                          <Modal.Title className="text-gray-50">Location</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="bg-gray-700">
                                <div dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d7065.846662994292!2d84.43899540000001!3d27.6887644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1622002286951!5m2!1sen!2snp" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>` }} />
                                </Modal.Body>
                                <Modal.Footer className="bg-gray-700">
                                      <Button variant="secondary" onClick={handleClose}>
                                        Close
                                      </Button>
                                </Modal.Footer>
                    </Modal>
        </>
    )
}

export default Location

import React from 'react'
import background from './ashok.jpg'

function Header() {
    return (
        <>
                    <div class="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center" style={{ backgroundImage: `url(${background})` }}></div>
                  
                  <h1 class="text-3xl font-bold pt-8 lg:pt-0 text-white">Ashok Bhattarai</h1>
                  <div class="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-purple-700 opacity-25"></div>
        </>
    )
}

export default Header

import React from 'react'
import Header from './Header'
import Information from './Information'
import SocialSites from './SocialSites'
import Contact from './Contact'
import Location from './Location'
import Skill from './Skill'

function WholeSection() {
    return (
        <>
                 <div    
                    id="profile"   
                    class="w-full lg:w-3/5 rounded-lg lg:rounded-l-lg lg:rounded-r-none       shadow-2xl bg-gray-800 opacity-75 mx-6 lg:mx-0">
                    
                    <div 
                        class="p-4 md:p-12 text-center lg:text-left" > 
                        <Header />
                        <Skill />
                        <Location />
                        <Information />

                        <div 
                            class="pt-12 pb-8">
                                <Contact />
                        </div>

                        <SocialSites />
                    </div>

            </div>
        </>
    )
}

export default WholeSection

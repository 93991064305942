// import React , { useState }from 'react'
import React ,{useState, useEffect}  from 'react'
import WholeSection from './WholeSection'
import SideImage from './SideImage'
import HashLoader from "react-spinners/HashLoader";
import { css } from "@emotion/react";

const override = css`
  display: flex;
  justify-content: center;
  text-items: center;
  width: 100wh;
  height: 80vh;
  margin: 0 auto;
  border-color: red;

`;

function Home() {
  const [loading, setLoading] = useState(false);

  useEffect( () => {
    setLoading(true)
    setTimeout( () => {
        setLoading(false);
    }, 3000)
  }, [])
   
    return (
        <>
        <div class="max-w-4xl lg:w-full  flex items-center justify-center items-center h-auto lg:h-screen flex-wrap mx-auto my-16 lg:my-6 lg:my-0"> 
        { 
          loading ?
          <HashLoader 
            color="000000" 
            css={override}
            loading={loading}  
            size={40} 
          />
            : <>
                  <WholeSection />
                <SideImage />
            </>
        }
            
            
        </div>
      </>
    )
}

export default Home

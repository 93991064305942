import React , { useState }from 'react'
import { Modal ,Button} from 'react-bootstrap'
import { Progress } from "reactstrap";

function Skill() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <p class="pt-4 font-bold flex items-center justify-center lg:justify-start text-white">
                        <svg onClick={handleShow}  class="h-4 fill-current text-purple-700 pr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z"/>
                        </svg>Back-End  Developer
            </p>

                    <Modal show={show} onHide={handleClose} >
                                <Modal.Header className="bg-gray-700" closeButton>
                                          <Modal.Title className="text-gray-50">Skills</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="bg-gray-700">
                                            <Progress className="mb-3" color="success" value={65}>Html and Css</Progress>
                                            <Progress className="mb-3" color="success" value={70} >Bootstrap</Progress>
                                            <Progress className="mb-3" color="success" value={50} >Javascript</Progress>
                                            <Progress className="mb-3" color="success" value={75}>Tailwindcss</Progress>
                                            <Progress className="mb-3" color="success" value={65}>Php and Laravel</Progress> 
                                            <Progress className="mb-3" color="success" value={50}>MySql</Progress>
                                            <Progress className="mb-3" color="success" value={30}>React Js</Progress>
                                            <Progress className="mb-3" color="success" value={20}>Node.js</Progress>
                                </Modal.Body>
                                <Modal.Footer className="bg-gray-700">
                                      <Button variant="secondary" onClick={handleClose}>
                                        Close
                                      </Button>
                                </Modal.Footer>
                    </Modal>
        </>
    )
}

export default Skill

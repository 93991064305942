import React from 'react'
import image from './ashok2.jpg'
function SideImage() {
    return (
        <>
            <div class="w-full lg:w-2/5">
                <img src={image}  class="rounded-none lg:rounded-lg shadow-2xl hidden lg:block" />
            </div>
        </>
    )
}

export default SideImage

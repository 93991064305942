import React , { useState }from 'react'
import { Modal ,Button ,Form} from 'react-bootstrap'

function Contact() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [status, setStatus] = useState("Send");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const {name, email, message } = e.target.elements;
    let details = {
      name:name.value,
      email: email.value,
      message: message.value,
    };
    let response = await fetch("https://ashok81.herokuapp.com/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    // let result = await response.json();
    // console.log(result.status);
  };

    return (
        <>
                <Button variant="primary"  onClick={() => setShow(true)}>
                           Get In Touch
                </Button>
                {' '}
                {' '}
                {' '}
                {' '}
                <Button variant="warning">
                           Project
                </Button>


                <Modal
                   
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-70w"
                    aria-labelledby="example-custom-modal-styling-title"
                  >
                    <Modal.Header  className="bg-gray-700" closeButton>
                      <Modal.Title className="text-gray-50" id="example-custom-modal-styling-title" >
                        Contact Form
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body  className="bg-gray-700">
                      <Form onSubmit={handleSubmit} >
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label className="text-gray-50">Username</Form.Label>
                                  <Form.Control type="text" placeholder="Ex. Joe " name="name" required/>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label className="text-gray-50">Your Email Address</Form.Label>
                                  <Form.Control type="email" placeholder="name@example.com" name="email" required/>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                  <Form.Label className="text-gray-50">Message</Form.Label>
                                  <Form.Control as="textarea" rows={3} name="message" required />
                          </Form.Group>
                          <Button variant="primary" type="submit" >{ status}</Button>{' '}{' '}{' '} {' '}
                          <Button variant="secondary" onClick={handleClose}> Close</Button>
                      </Form>
                    </Modal.Body>
                  </Modal>
        </>
    )
}

export default Contact

import React from 'react'

function Information() {
    return (
        <div>
            <p class="pt-2 text-gray-100 text-xs lg:text-sm flex items-center justify-center lg:justify-start">
                        <svg xmlns="http://www.w3.org/2000/svg"  class="h-4 fill-current text-purple-700 pr-4" viewBox="0 0 16 16">
                            <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                            <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                          </svg> +(977) 9845806370
                    </p>
                    <p class="pt-8 text-sm text-white">
                       Before going into my qualifications and abilities, though, let me introduce myself. I am Ashok Bhattarai, from Nepal. Currently, I am a graduate of The University of Tribhuvan Vishwavidyalaya in Computer Science and Information Technology and I have been taking lessons in modern web developing. Due to my education background, I am perfectly suited to this field, and I have had my training to become an expert in it. 
                    </p>
        </div>
    )
}

export default Information
